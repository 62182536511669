import { AppState } from '../../../../../store/AppState.interface'
import { getAvailableClients } from '../../../../../store/reducers/clientList.reducer'

export interface AvailableClientsVM {
  clients: ClientItemVM[]
}

export interface ClientItemVM {
  uuid: string
  name: string
  email: string
  href: string
}

const clientsPath = '/clients'

export const getAvailableClientsVM = (state: AppState): AvailableClientsVM => {
  const availableClientsSelector = getAvailableClients(state)
  return {
    clients: availableClientsSelector.map(c => {
      return {
        uuid: c.uuid,
        name: c.name,
        email: c.email,
        href: `${clientsPath}/${c.uuid}`
      }
    })
  }
}
