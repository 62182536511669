import { ActionsUnion, createAction } from '../../../store/configureStore'
import { Client } from './client.interface'

export const Actions = {
  clientsListed: (clients: any[]) => createAction('AVAILABLE_CLIENTS_LISTED', { clients }),
  clientCreated: (client: Client) => createAction('CLIENT_CREATED', { client }),
  clientEdited: (client: Client) => createAction('CLIENT_EDITED', { client }),
  clientInvited: (client: Client) => createAction('CLIENT_INVITED', { client })
}

export type Actions = ActionsUnion<typeof Actions>
