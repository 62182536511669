import { Member } from '../../../../../corelogic/usecases/member/Member.interface'
import { CreateMemberVM } from '../create-member-generators/createMemberViewModelGenerator'

export const editMemberVM = (member: Member) => {
  return new EditMemberVM(member)
}

export class EditMemberVM extends CreateMemberVM {
  constructor(member: Member) {
    super()
    this.member.uuid = member.uuid
    this.member.email = member.email
    this.member.availableDocumentTypes = member.availableDocumentTypes
  }

  copy(): EditMemberVM {
    const res = new EditMemberVM({uuid: this.member.uuid || '', email: this.email, availableDocumentTypes: JSON.parse(JSON.stringify(this.rights))})
    return res
  }

  get uuid(): string {
    return this.member.uuid || ''
  }

  get canChangeEmail(): boolean {
    return false
  }
}
