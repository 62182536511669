import { ThunkResult } from '../../../../store/configureStore'
import { Actions } from '../actionCreators'
import {
  FileType
} from '../../../../adapters/primary/view-model-generators/documents/available-files-generators/availableFilesViewModelGenerator'
import { MemberGateway } from '../../../gateways/memberGateway.interface'
import { getCurrentUser } from '../../../../store/reducers/currentUser.reducer'

export const editMemberRights: (uuid: string, newRights: Array<FileType>) => ThunkResult<Promise<void>> =
  (uuid, newRights: Array<FileType>) => async (dispatch, getState,
    { memberGateway }: { memberGateway: MemberGateway }) => {
    const currentUser = getCurrentUser(getState())
    const member = await memberGateway.editRights(uuid, newRights, currentUser.uuid)
    dispatch(Actions.memberEdited(member))
  }
