export const links = [
  {
    name: 'Factures',
    url: '/factures'
  },
  {
    name: 'Devis',
    url: '/devis'
  },
  {
    name: 'Audits',
    url: '/audits'
  },
  {
    name: 'Process',
    url: '/process'
  },
  {
    name: 'Ressources humaines',
    url: '/ressources-humaines'
  }
]
