import React, { ReactNode } from 'react'

interface ButtonProps {
  children: ReactNode
  onClicked: any
}

export const SecondaryButton = (props: ButtonProps) => {
  return (
    <div
      className='flex content-center hover:cursor-pointer inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
      onClick={() => props.onClicked()}
    >
      {props.children}
    </div>
  )
}
