import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listAvailableMembers } from '../../../../corelogic/usecases/member/available-members-listing/listAvailableMembers'
import {
  getAvailableMembersVM,
  MemberVM
} from '../../view-model-generators/members/available-members-generators/availableMembersViewModelGenerator'
import { AddButton } from '../shared/add-button.component'
import {
  CreateMemberVM,
  createMemberVM
} from '../../view-model-generators/members/create-member-generators/createMemberViewModelGenerator'
import { MemberForm } from './member-form.component'
import {
  EditMemberVM,
  editMemberVM
} from '../../view-model-generators/members/edit-member-generators/editMemberViewModelGenerator'
import { getAvailableMembers } from '../../../../store/reducers/memberList.reducer'
import { editMemberRights } from '../../../../corelogic/usecases/member/member-edition/editMember'
import { createNewMember } from '../../../../corelogic/usecases/member/member-creation/createMember'

export const AvailableMembersListing = () => {
  const dispatch = useDispatch()
  const membersVM = useSelector(getAvailableMembersVM).members
  const [displayAddNewMember, setDisplayAddNewMember] = useState<boolean>(false)
  const members = useSelector(getAvailableMembers)

  useEffect(() => {
    dispatch(listAvailableMembers)
  }, [dispatch])

  const header = () => {
    const headers = ['Email', 'Factures', 'Devis', 'Audit', 'Process', 'Ressources Humaines']
    const ths: Array<any> = []
    headers.forEach((h) => {
      const align = h === 'Email' ? 'left' : 'center'
      ths.push(
        <th
          key={h}
          scope="col"
          className={`py-3.5 pl-4 pr-3 text-${align} text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0`}
        > {h} </th>
      )
    })
    return <thead>
      <tr>
        {ths}
      </tr>
    </thead>
  }

  const onEditMember = async (memberVM: EditMemberVM) => {
    await dispatch(editMemberRights(memberVM.uuid, memberVM.rights))
  }

  const onCreateMember = (memberVM: CreateMemberVM) => {
    setDisplayAddNewMember(false)
    dispatch(createNewMember(memberVM.email, memberVM.rights))
  }

  const onCancel = () => {
    setDisplayAddNewMember(false)
  }

  const allMembers = () => {
    const res: Array<any> = []
    membersVM.forEach((m: MemberVM) => {
      const member = members.find((me) => me.uuid === m.uuid)
      if (!member) throw Error()
      res.push(<MemberForm key={m.uuid} manageMemberVM={editMemberVM(member)} validate={onEditMember}/>)
    })
    return res
  }

  const addNewMember = () => {
    if (!displayAddNewMember) {
      return (
        <tr className='pt-2'>
          <AddButton text='Ajouter un membre' onClicked={() => setDisplayAddNewMember(true)}/>
        </tr>
      )
    }
    return (
      <MemberForm key='new-member' manageMemberVM={createMemberVM()} validate={onCreateMember} cancel={onCancel}/>
    )

  }

  const membersTable = () => {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
          <table className="min-w-full divide-y divide-gray-300 border-collapse p-8">
            {header()}
            <tbody>
              {allMembers()}
              {addNewMember()}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  return (
    <div className="pb-5 border-b border-gray-200">
      {membersTable()}
    </div>
  )
}
