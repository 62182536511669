import React from 'react'
import { siteName, year } from '../../../../metadata'

const Footer = () => {
  return (
    <>
      <footer className="bg-secondary py-3 px-4 sm:px-6 text-right lg:px-8">
        <p className="text-base text-white">&copy; {year} {siteName}</p>
      </footer>
    </>
  )
}

export default Footer
