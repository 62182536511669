import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from '../../../../store/reducers/currentUser.reducer'

const PrivateRoute = ({ children }: any) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)
  const navigate = useNavigate()

  useEffect(() => {
    if (!currentUser) {
      navigate('/login')
    }
  }, [dispatch, currentUser])

  if (!currentUser) {
    return null
  }
  return children
}

export default PrivateRoute
