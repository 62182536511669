import { AppState } from '../../../../../store/AppState.interface'
import { getAvailableClients } from '../../../../../store/reducers/clientList.reducer'

export interface ClientVM {
  uuid: string
  name: string,
  email: string,
  invited: boolean
}

export class ClientNotFoundError extends Error {
  constructor(clientId: string) {
    super(`Client ${clientId} not found`)
  }
}

export const getClientVM = (clientId: string) => (state: AppState): ClientVM => {
  const availableClientsSelector = getAvailableClients(state)
  const found = availableClientsSelector.find((c) => c.uuid === clientId)
  if (!found) throw new ClientNotFoundError(clientId)
  return found
}
