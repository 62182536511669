import { Client } from '../../../../../corelogic/usecases/client/client.interface'

export class CreateClientVM {
  protected client: Partial<Client> = {}

  constructor() {
    this.client.name = ''
    this.client.email = ''
  }

  get name(): string {
    return this.client.name || ''
  }

  set name(name: string) {
    this.client.name = name
  }

  get email(): string {
    return this.client.email || ''
  }

  set email(email: string) {
    this.client.email = email
  }
}

export const createClientVM = (): CreateClientVM => {
  return new CreateClientVM()
}
