import { AuthenticationGateway } from '../../corelogic/gateways/authenticationGateway.interface'
import { User } from '../../corelogic/usecases/user-login/User.interface'
import { UserRole } from '../../corelogic/usecases/client/available-clients-listing/userRole'
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js'

export class Auth0AuthenticationGateway implements AuthenticationGateway {
  private auth0Client: Auth0Client | undefined = undefined
  private redirectUri = ''
  private roleUrl = ''

  async init(domain: string, clientId: string, redirectUri: string, roleUrl: string) {
    this.roleUrl = roleUrl
    this.redirectUri = redirectUri
    this.auth0Client = await createAuth0Client({
      client_id: clientId,
      domain: domain,
      redirect_uri: redirectUri
    })
  }

  async authenticate(_email: string, _password: string): Promise<User> {
    if (!this.auth0Client) {
      throw Error()
    }
    await this.auth0Client.loginWithPopup()
    const user = await this.auth0Client.getUser()
    if (!user || !user.email || !user.sub) {
      throw Error()
    }
    // const accessToken = await this.auth0Client.getTokenSilently()
    const auth0roles = user[this.roleUrl][0]
    const roles: Array<UserRole> = []
    if (auth0roles && auth0roles.includes('Admin')) {
      roles.push(UserRole.Admin)
    } else {
      roles.push(UserRole.Client)
    }
    return Promise.resolve({ uuid: user.sub, email: user.email, roles })
  }

  async logout(): Promise<boolean> {
    if (!this.auth0Client) {
      throw Error()
    }
    await this.auth0Client.logout({ returnTo: this.redirectUri })
    return Promise.resolve(true)
  }
}
