import { ThunkResult } from '../../../store/configureStore'
import { Actions } from './actionCreators'
import { FileGateway } from '../../gateways/fileGateway.interface'
import { getCurrentUser } from '../../../store/reducers/currentUser.reducer'

export const listAvailableFiles: ThunkResult<Promise<void>> =
  async (dispatch, getState,
    { fileGateway }: { fileGateway: FileGateway }) => {
    const currentUser = getCurrentUser(getState())
    const files = await fileGateway.listFiles(currentUser.uuid)
    dispatch(Actions.filesListed(files))
  }
