import React from 'react'
import { AvailableMembersListing } from '../available-member-listing/availableMembersListing.component'

export const Members = () => {
  return (
    <main>
      <div className="container mx-auto">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h2 className="pb-10">Membres</h2>
            <AvailableMembersListing/>
          </div>
        </div>
      </div>
    </main>
  )
}
