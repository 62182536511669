import React, { ReactNode } from 'react'

interface ButtonProps {
  children: ReactNode
  onClicked: any
}

export const PrimaryButton = (props: ButtonProps) => {
  return (
    <div
      className='flex content-center hover:cursor-pointer inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
      onClick={() => props.onClicked()}
    >
      {props.children}
    </div>
  )
}
