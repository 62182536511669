import { Action, applyMiddleware, combineReducers, createStore, Store } from 'redux'
import thunk, { ThunkAction, ThunkDispatch, ThunkMiddleware } from 'redux-thunk'
import { AppState } from './AppState.interface'
import { coreLogic } from './reducers/coreLogic.reducer'
import { composeWithDevTools } from 'redux-devtools-extension'
import { AuthenticationGateway } from '../corelogic/gateways/authenticationGateway.interface'
import { FileGateway } from '../corelogic/gateways/fileGateway.interface'
import storage from 'redux-persist/lib/storage'
import { createMigrate, persistReducer } from 'redux-persist'
import { ClientGateway } from '../corelogic/gateways/clientGateway.interface'
import { MigrationManifest } from 'redux-persist/es/types'
import { MemberGateway } from '../corelogic/gateways/memberGateway.interface'

interface Dependencies {
  authenticationGateway: AuthenticationGateway
  fileGateway: FileGateway
  clientGateway: ClientGateway
  memberGateway: MemberGateway
}

const migrations: MigrationManifest = {
  0: (state: any): any => {
    return {
      ...state,
      test: 'plop'
    }
  },
  1: (state: any): any => {
    return {
      ...state,
      coreLogic: {
        ...state.coreLogic,
        fileList: {
          ...state.coreLogic.fileList,
          filters: {}
        }
      }
    }
  },
  2: (state: any): any => {
    return {
      ...state,
    }
  }
}

export const persistConfig = {
  key: 'coreLogic',
  storage,
  version: 2,
  migrate: createMigrate(migrations, { debug: false })
}

const persistedReducer = persistReducer(persistConfig, coreLogic)

export const clearStore = async () => {
  await storage.removeItem('persist:coreLogic')
}

export const configureReduxStore = (dependencies: Partial<Dependencies>): Store<AppState> => {
  return createStore(
    combineReducers({
      coreLogic: persistedReducer
    }),
    composeWithDevTools(
      applyMiddleware(thunk.withExtraArgument(dependencies) as ThunkMiddleware<AppState, Action, Dependencies>))
  )

}

export type ReduxStore = Store<AppState> & { dispatch: ThunkDispatch<AppState, Dependencies, Action> }
export type ThunkResult<R> = ThunkAction<R, AppState, Dependencies, Action>;

export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P;
}

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(type: T, payload?: P): ActionWithPayload<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? {type} : {type, payload}
}

// eslint-disable-next-line
type FunctionType = (...args: any[]) => any
type ActionCreatorsMapObject = { [actionCreator: string]: FunctionType };

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>;
