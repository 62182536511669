import { ThunkResult } from '../../../store/configureStore'
import { getFileFilters } from '../../../store/reducers/fileList.reducer'
import { Actions } from '../available-file-listing/actionCreators'
import {
  FileType
} from '../../../adapters/primary/view-model-generators/documents/available-files-generators/availableFilesViewModelGenerator'

export const resetSearchFilter: ThunkResult<Promise<void>> =
  async (dispatch, getState) => {
    dispatch(Actions.searchFilterReset())
  }

