import { ThunkResult } from '../../../../store/configureStore'
import { Actions } from '../actionCreators'
import { ClientGateway } from '../../../gateways/clientGateway.interface'

export class ClientDoesNotExistsError extends Error {
  constructor(uuid: string) {
    super(`Client ${uuid} does not exists`)
  }
}

export const inviteClient: (uuid: string) => ThunkResult<Promise<void>> =
  (uuid) => async (dispatch, _getState,
    { clientGateway }: { clientGateway: ClientGateway }) => {
    try {
      const clientInvited = await clientGateway.invite(uuid)
      dispatch(Actions.clientInvited(clientInvited))
    } catch (e: any) {
      throw new ClientDoesNotExistsError(uuid)
    }
  }
