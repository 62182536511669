import { FileGateway } from '../../corelogic/gateways/fileGateway.interface'
import { PlFile } from '../../corelogic/usecases/available-file-listing/PlFile.interface'

export class ApiFileGateway implements FileGateway {
  private readonly path: string

  constructor(path: string) {
    this.path = path
  }

  async rename(uuid: string, newName: string): Promise<PlFile> {
    const res = await fetch(`${this.path}/document/rename/${uuid}`, { method: 'PATCH', body: JSON.stringify({ name: newName })})
    return await res.json()
  }

  async delete(uuid: string): Promise<PlFile> {
    const res = await fetch(`${this.path}/document/${uuid}`, { method: 'DELETE' })
    return {
      uuid: '',
      clientUuid: '',
      name: '',
      url: ''
    }
  }

  async listFiles(userUuid: string): Promise<PlFile[]> {
    const res = await fetch(`${this.path}/documents/${userUuid}`)
    const jsonRes = await res.json()
    return jsonRes.map((d: any) => {
      return {
        uuid: d.uuid,
        clientUuid: d.clientUuid,
        name: d.name,
        url: `${this.path}${d.url}`
      }
    })
  }

  async upload(clientUuid: string, userUuid: string, ...files: Array<File>): Promise<PlFile[]> {
    const formData = new FormData()
    files.forEach((f) => {
      formData.append('files', f)
    })
    const res = await fetch(`${this.path}/documents/upload/${userUuid}/${clientUuid}`, {
      method: 'POST',
      body: formData
    })
    const jsonRes = await res.json()
    return jsonRes.map((d: any) => {
      return {
        uuid: d.uuid,
        clientUuid: d.clientUuid,
        name: d.name,
        url: d.url
      }
    })
  }
}

