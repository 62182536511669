import React, { useState } from 'react'
import {
  FileType
} from '../../view-model-generators/documents/available-files-generators/availableFilesViewModelGenerator'
import { Checkbox } from '../shared/checkbox.component'
import { getEnumKeys } from '../../../../corelogic/utils/enumsUtils'
import {
  CreateMemberVM
} from '../../view-model-generators/members/create-member-generators/createMemberViewModelGenerator'
import { PrimaryButton } from '../shared/primary-button.component'
import { CheckIcon, XIcon } from '@heroicons/react/solid'
import { SecondaryButton } from '../shared/secondary-button.component'

export interface MemberProps {
  manageMemberVM: CreateMemberVM
  validate?: any
  cancel?: any
}

export const MemberForm = (props: MemberProps) => {
  const [memberVM, setMemberVM] = useState(props.manageMemberVM.copy())
  const [isDirty, setIsDirty] = useState(false)

  const cancelClicked = () => {
    setMemberVM(props.manageMemberVM.copy())
    setIsDirty(false)
    if (props.cancel) {
      props.cancel()
    }
  }

  const validateClicked = () => {
    props.validate(memberVM)
    setIsDirty(false)
  }

  const handleEmailChanged = (e: any) => {
    e.preventDefault()
    setIsDirty(true)
    memberVM.email = e.target.value
    setMemberVM(Object.assign(memberVM.copy(), memberVM))
  }

  const handleRightChange = async (documentType: FileType) => {
    setIsDirty(true)
    const index = memberVM.rights.findIndex((r) => r === documentType)
    if (index < 0) {
      memberVM.rights.push(documentType)
    } else {
      memberVM.rights.splice(index, 1)
    }
    setMemberVM(Object.assign(memberVM.copy(), memberVM))
  }

  const emailField = () => {
    if (!memberVM.canChangeEmail) {
      return <td className="py-4">{memberVM.email}</td>
    }
    return (
      <td className="py-4 flex items-center">
        <div className='pr-2'>Email: </div>
        <input
          id="email"
          autoFocus
          name="email"
          type="email"
          required
          className="appearance-none block w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-tertiary focus:border-tertiary sm:text-sm"
          value={memberVM.email}
          onChange={handleEmailChanged}
        />
      </td>
    )
  }

  const actions = () => {
    if (!isDirty) return null
    return (
      <td className='flex items-center gap-4'>
        <PrimaryButton onClicked={() => validateClicked()}>
          <CheckIcon className="w-5 h-5 mr-1 text-white"/>
        </PrimaryButton>
        <SecondaryButton onClicked={() => cancelClicked()}>
          <XIcon className="w-5 h-5 mr-1 text-gray-700"/>
        </SecondaryButton>
      </td>
    )
  }

  const memberLine = () => {
    const rights = memberVM.rights
    const allDocumentTypes = getEnumKeys(FileType)
    const allRights: Array<any> = []
    allDocumentTypes.forEach((documentType) => {
      const checked = rights.find((r) => r === documentType) ? true : false
      allRights.push( <td key={`${memberVM.email}.${documentType}`} className="text-center">
        <Checkbox checked={checked} name={documentType} onClicked={() => handleRightChange(documentType)}/>
      </td>
      )
    })
    return (
      <tr key={memberVM.email} className="text-gray-900 py-3">
        {emailField()}
        {allRights}
        {actions()}
      </tr>
    )
  }

  return (
    memberLine()
  )
}
