import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CreateClientVM
} from '../../view-model-generators/clients/create-client-generators/createClientViewModelGenerator'
import { useNavigate, useParams } from 'react-router-dom'
import ClientForm, { AvailableActions } from '../shared/client-form.component'
import { getClientVM } from '../../view-model-generators/clients/get-client-generators/getClientViewModelGenerator'
import { editClientVM } from '../../view-model-generators/clients/edit-client-generators/editClientViewModelGenerator'
import { Client } from '../../../../corelogic/usecases/client/client.interface'
import { editClient } from '../../../../corelogic/usecases/client/client-edition/editClient'

export default function EditClient() {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const clientId = params.clientId || ''
  let client: Client
  try {
    client = useSelector(getClientVM(clientId))
  } catch (e) {
    console.log('error: ', e)
  }

  const handleEditClient = (clientVM: CreateClientVM) => {
    dispatch(editClient(clientId, clientVM.name, clientVM.email))
    navigate(`/clients/${clientId}`)
  }

  const handleCancel = () => {
    navigate(`/clients/${clientId}`)
  }

  return (
    <>
      <ClientForm
        action={AvailableActions.Edit}
        manageClientVM={editClientVM(client)}
        onSubmit={handleEditClient}
        onCancel={handleCancel}
      />
    </>
  )
}
