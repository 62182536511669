import React, { useEffect } from 'react'
import { ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import { listAvailableClients } from '../../../../corelogic/usecases/client/available-clients-listing/listAvailableClients'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAvailableClientsVM
} from '../../view-model-generators/clients/available-clients-generators/availableClientsViewModelGenerator'
import { useNavigate } from 'react-router-dom'

export const Clients = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { clients } = useSelector(getAvailableClientsVM)

  useEffect(() => {
    dispatch(listAvailableClients)
  }, [dispatch])

  const handleNewClient = (e: any) => {
    e.preventDefault()
    navigate('/clients/new')
  }

  const handleClientSelected = (href: string) => (e: any) => {
    e.preventDefault()
    navigate(href)
  }

  return (
    <main>
      <div className="container mx-auto">
        <div className="flex justify-between mb-8">
          <h1>Clients</h1>
          <button
            className="button"
            onClick={handleNewClient}
          >
            Nouveau client
          </button>
        </div>
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            {clients.map((client) => (
              <li key={client.email}>
                <div className="block hover:bg-gray-50 hover:cursor-pointer" onClick={handleClientSelected(client.href)}>
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p className="text-sm font-medium text-tertiary truncate">{client.name}</p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <span className="truncate">{client.email}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </main>
  )
}
