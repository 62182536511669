import React, { Fragment, useEffect } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, PlusCircleIcon, XIcon } from '@heroicons/react/outline'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from '../../../../store/reducers/currentUser.reducer'
import { links } from '../../../../links'
import { ChevronDownIcon } from '@heroicons/react/solid'
import {
  getAvailableClientsVM
} from '../../view-model-generators/clients/available-clients-generators/availableClientsViewModelGenerator'
import {
  listAvailableClients
} from '../../../../corelogic/usecases/client/available-clients-listing/listAvailableClients'
import { UserRole } from '../../../../corelogic/usecases/client/available-clients-listing/userRole'

const home = {
  name: 'Accueil',
  url: '/'
}

const linksWithHome: Array<any> = [...links]
linksWithHome.unshift(home)

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

function isCurrent(link: any) {
  const currentPath = useLocation().pathname
  return link.url === currentPath
}

export default function NavBar() {
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)
  const { clients } = useSelector(getAvailableClientsVM)

  const displayMemberMenu = (): any => {
    if (!currentUser || (!currentUser.roles.includes(UserRole.Admin) && !currentUser.roles.includes(UserRole.Client))) {
      return null
    }
    return (
      <>
        <div className="border-t border-gray-200"/>
        <Menu.Item>
          {({ active }) => (
            <a
              href='/membres'
              className={classNames(
                active ? 'bg-gray-100' : '',
                'block py-2 px-4 text-sm text-gray-700'
              )}
            >
              Gérer membres
            </a>
          )}
        </Menu.Item>
      </>
    )
  }

  const displayClientMenu = (): any => {
    if (!currentUser || !currentUser.roles.includes(UserRole.Admin)) {
      return null
    }
    return (
      <>
        <Menu.Item>
          {({ active }) => (
            <a
              href='/clients'
              className={classNames(
                active ? 'bg-gray-100' : '',
                'block py-2 px-4 text-sm text-gray-700'
              )}
            >
              Gérer clients
            </a>
          )}
        </Menu.Item>
        <div className="border-t border-gray-200"/>
        {clients.map((client) => (
          <Menu.Item key={client.name}>
            {({ active }) => (
              <a
                href={client.href}
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block py-2 px-4 text-sm text-gray-700'
                )}
              >
                {client.name}
              </a>
            )}
          </Menu.Item>
        ))}
        <Menu.Item>
          {({ active }) => (
            <a
              href='/clients/new'
              className={classNames(
                active ? 'bg-gray-100' : '',
                'block py-2 px-4 text-sm text-gray-700 flex items-center'
              )}
            >
              <PlusCircleIcon className="w-5 h-5 mr-1 text-gray-700"/>
                Nouveau client
            </a>
          )}
        </Menu.Item>
      </>
    )
  }

  const displayClientPanel = (): any => {
    if (!currentUser || !currentUser.roles.includes(UserRole.Admin)) {
      return null
    }
    return (
      <>
        <Disclosure.Button
          as="a"
          href='/clients'
          className='text-white hover:bg-tertiary hover:bg-opacity-75 block px-3 py-2 rounded-md text-base font-medium'
        >
          Gérer clients
        </Disclosure.Button>
        <div className="border-t border-gray-200"/>
        {clients.map((client) => (
          <Disclosure.Button
            key={client.name}
            as="a"
            href={client.href}
            className={classNames(
              isCurrent(client)
                ? 'bg-tertiary text-white'
                : 'text-white hover:bg-tertiary hover:bg-opacity-75',
              'block px-3 py-2 rounded-md text-base font-medium'
            )}
            aria-current={isCurrent(client) ? 'page' : undefined}
          >
            {client.name}
          </Disclosure.Button>
        ))}
        <Disclosure.Button
          as="a"
          href='/clients/new'
          className='text-white hover:bg-tertiary hover:bg-opacity-75 block px-3 py-2 rounded-md text-base font-medium flex items-center'
        >
          <PlusCircleIcon className="w-5 h-5 mr-1"/>
          Nouveau client
        </Disclosure.Button>
        <div className="border-t border-gray-200"></div>
      </>
    )
  }

  const displayMemberPanel = (): any => {
    if (!currentUser || (!currentUser.roles.includes(UserRole.Admin) && !currentUser.roles.includes(UserRole.Client))) {
      return null
    }
    return (
      <>
        <Disclosure.Button
          as="a"
          href='/membres'
          className='text-white hover:bg-tertiary hover:bg-opacity-75 block px-3 py-2 rounded-md text-base font-medium'
        >
          Gérer membres
        </Disclosure.Button>
        <div className="border-t border-gray-200"/>
      </>
    )
  }

  useEffect(() => {
    if (currentUser && currentUser.roles.includes(UserRole.Admin)) {
      dispatch(listAvailableClients)
    }
  }, [dispatch, currentUser])

  if (!currentUser) {
    return null
  }

  return (
    <div className="min-h-full">
      <Disclosure as="nav" className="bg-secondary">
        {({ open }) => (
          <>
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-20">
                <div className="flex-shrink-0">
                  <a href="/">
                    <img
                      className="h-8 w-2/3 md:w-auto"
                      src="/pharlogCloudLogo.svg"
                      alt="pharlog cloud logo"
                    />
                  </a>
                </div>
                <div className="hidden lg:block">
                  <div className="flex items-baseline space-x-4">
                    {linksWithHome.map((link: any) => (
                      <a
                        key={link.name}
                        href={link.url}
                        className={classNames(
                          isCurrent(link)
                            ? 'bg-tertiary text-white'
                            : 'text-white hover:bg-tertiary hover:bg-opacity-75',
                          'px-3 py-2 rounded-md lg:text-lg font-medium'
                        )}
                        aria-current={isCurrent(link) ? 'page' : undefined}
                      >
                        {link.name}
                      </a>
                    ))}
                  </div>
                </div>
                <div className="flex items-center hidden lg:block">
                  <Menu as="div" className="flex-shrink-0 relative ml-4">
                    <div>
                      <Menu.Button className="flex text-white justify-center items-center">
                        <span className="sr-only">Open user menu</span>
                        <div className="text-lg py-4 px-6 text-white">{currentUser.email}</div>
                        <ChevronDownIcon className="h-5 w-5 text-white"/>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                        {displayClientMenu()}
                        {displayMemberMenu()}
                        <div className="pt-4 pb-3 border-t border-gray-200">
                          <div className="ml-2 space-y-1">
                            <Menu.Item as="a" href="/logout" className="button text-lg space-y-2 py-2 px-6 rounded-full text-white">
                              Se déconnecter
                            </Menu.Item>
                          </div>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="-mr-2 flex lg:hidden">
                  <Disclosure.Button className="bg-tertiary inline-flex items-center justify-center p-2 rounded-md text-indigo-200 hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {displayClientPanel()}
                {displayMemberPanel()}
                {links.map((link) => (
                  <Disclosure.Button
                    key={link.name}
                    as="a"
                    href={link.url}
                    className={classNames(
                      isCurrent(link)
                        ? 'bg-tertiary text-white'
                        : 'text-white hover:bg-tertiary hover:bg-opacity-75',
                      'block px-3 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={isCurrent(link) ? 'page' : undefined}
                  >
                    {link.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-200">
                <div className="ml-3 space-y-1">
                  <Disclosure.Button as="a" href="/logout" className="button text-lg space-y-2 py-2 px-6 rounded-full text-white">
                    Se déconnecter
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}
