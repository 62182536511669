import { ThunkResult } from '../../../../store/configureStore'
import { Actions } from '../actionCreators'
import { ClientGateway } from '../../../gateways/clientGateway.interface'
import { getCurrentUser } from '../../../../store/reducers/currentUser.reducer'
import { UserRole } from './userRole'

export class UnauthorizedError extends Error {
  constructor() {
    super('Forbidden')
  }
}

export const listAvailableClients: ThunkResult<Promise<void>> =
  async (dispatch, getState,
    { clientGateway }: { clientGateway: ClientGateway }) => {
    const currentUser = getCurrentUser(getState())
    if (!currentUser || !currentUser.roles.includes(UserRole.Admin)) {
      throw new UnauthorizedError()
    }
    const clients = await clientGateway.listClients(currentUser.uuid)
    dispatch(Actions.clientsListed(clients))
  }
