import { combineReducers } from 'redux'
import { AppState} from '../AppState.interface'
import * as actionCreators from '../../corelogic/usecases/client/actionCreators'
import { Client } from '../../corelogic/usecases/client/client.interface'

const data = (state: Client[] = [], action: actionCreators.Actions) => {
  if (action.type === 'AVAILABLE_CLIENTS_LISTED') {
    return action.payload.clients
  }
  if (action.type === 'CLIENT_CREATED') {
    return [
      ...state.slice(0, state.length),
      action.payload.client
    ]
  }
  if (action.type === 'CLIENT_EDITED' || action.type === 'CLIENT_INVITED') {
    const index = state.findIndex((c) => c.uuid === action.payload.client.uuid)
    return [
      ...state.slice(0, index),
      action.payload.client,
      ...state.slice(index + 1, state.length)
    ]
  }
  return state
}

export const clientList = combineReducers({
  data
})

export const getAvailableClients = (state: AppState) => state.coreLogic.clientList.data
