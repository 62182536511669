import { UuidGenerator } from '../../corelogic/usecases/client/client-creation/uuidGenerator.interface'

export class FakeUuidGenerator implements UuidGenerator {
  private nextUuids: Array<string> = []

  generate(): string {
    const nextUuid = this.nextUuids[0]
    this.nextUuids.shift()
    return nextUuid
  }

  setNextUuids(...nextUuids: Array<string>) {
    this.clear()
    this.nextUuids = nextUuids
  }

  private clear() {
    this.nextUuids = []
  }
}
