import React, { useEffect, useState } from 'react'
import { PlusCircleIcon } from '@heroicons/react/solid'

export interface AddButtonProps {
  text: string
  onClicked: any
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export const AddButton = (props: AddButtonProps) => {
  return (
    <div
      className='flex content-center hover:cursor-pointer'
      onClick={() => props.onClicked()}
    >
      <PlusCircleIcon className="w-5 h-5 mr-1 text-gray-700"/>
      {props.text}
    </div>
  )
}
