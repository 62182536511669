import { combineReducers } from 'redux'
import { AppState} from '../AppState.interface'
import * as actionCreators from '../../corelogic/usecases/file-uploading/actionCreators'
import { PlFile } from '../../corelogic/usecases/available-file-listing/PlFile.interface'

const data = (state: PlFile[] = [], action: actionCreators.Actions) => {
  if (action.type === 'FILES_UPLOADED') {
    return action.payload.files
  }
  return state
}

const uploading = (state = false, action: actionCreators.Actions) => {
  if (action.type === 'FILES_UPLOADING')
    return true
  if (action.type === 'FILES_UPLOADED' || action.type === 'FILE_UPLOAD_TIMEOUT')
    return false
  return state
}

export const uploadedFileList = combineReducers({
  data,
  uploading,
})

export const getUploadedFiles = (state: AppState) => state.coreLogic.uploadedFileList.data
export const isUploading = (state: AppState) => state.coreLogic.uploadedFileList.uploading
