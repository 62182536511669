import React from 'react'
import { useDispatch } from 'react-redux'
import {
  CreateClientVM,
  createClientVM
} from '../../view-model-generators/clients/create-client-generators/createClientViewModelGenerator'
import { createNewClient } from '../../../../corelogic/usecases/client/client-creation/createClient'
import { useNavigate } from 'react-router-dom'
import ClientForm, { AvailableActions } from '../shared/client-form.component'

export default function NewClient() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleNewClient = (clientVM: CreateClientVM) => {
    dispatch(createNewClient(clientVM.name, clientVM.email))
    navigate('/clients')
  }

  const handleCancel = (e: any) => {
    e.preventDefault()
    navigate('/clients')
  }

  return (
    <>
      <ClientForm
        action={AvailableActions.Create}
        manageClientVM={createClientVM()}
        onSubmit={handleNewClient}
        onCancel={handleCancel}
      />
    </>
  )
}
