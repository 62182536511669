import { ThunkResult } from '../../../../store/configureStore'
import { Actions } from '../actionCreators'
import { ClientGateway } from '../../../gateways/clientGateway.interface'
import { getCurrentUser } from '../../../../store/reducers/currentUser.reducer'

export const createNewClient: (name: string, email: string) => ThunkResult<Promise<void>> =
  (name, email) => async (dispatch, getState,
    { clientGateway }: { clientGateway: ClientGateway }) => {
    const currentUser = getCurrentUser(getState())
    const client = await clientGateway.create(name, email, currentUser.uuid)
    dispatch(Actions.clientCreated(client))
  }
