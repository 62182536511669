import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from '../../../../store/reducers/currentUser.reducer'
import { UserRole } from '../../../../corelogic/usecases/client/available-clients-listing/userRole'

const AdminRoute = ({ children }: any) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)
  const navigate = useNavigate()

  useEffect(() => {
    if (!currentUser) {
      navigate('/login')
    } else if (!currentUser.roles.includes(UserRole.Admin)) {
      navigate('/403')
    }
  }, [dispatch, currentUser])

  if (!currentUser) {
    return null
  }
  return children
}

export default AdminRoute
