import { AppState } from '../../../../../store/AppState.interface'
import { getAvailableFiles, getFileFilters } from '../../../../../store/reducers/fileList.reducer'
import { getFileDate, getFileType } from './file-utils'
import { PlFile } from '../../../../../corelogic/usecases/available-file-listing/PlFile.interface'
import { getCurrentUser } from '../../../../../store/reducers/currentUser.reducer'
import { UserRole } from '../../../../../corelogic/usecases/client/available-clients-listing/userRole'
import { getAvailableClients } from '../../../../../store/reducers/clientList.reducer'

export interface AvailableFilesVM {
  headers: Header[]
  files: FileItemVM[]
}

export interface Header {
  label: string
  sortable: boolean
  dataKey: string
}

export interface FileItemVM {
  uuid: string
  name: string
  type: FileType
  clientName: string
  date: string
  url: string
  canDelete: boolean
  canRename: boolean
}

export enum FileType {
  InVoice = 'FACTURE',
  Quote = 'DEVIS',
  Audit = 'AUDIT',
  Process = 'PROCESS',
  HumanResources = 'RESSOURCES HUMAINES',
}

export enum SortType {
  None,
  Ascending,
  Descending
}

export class AvailableFilesHeadersFactory {
  private readonly headers: Header[]
  private defaultHeaders: Header[] = [
    {
      label: 'Nom',
      sortable: true,
      dataKey: 'name'
    },
    {
      label: 'Date',
      sortable: true,
      dataKey: 'date'
    },
    {
      label: 'Actions',
      sortable: false,
      dataKey: 'actions'
    }
  ]
  constructor() {
    this.headers = this.defaultHeaders
  }

  withFileType() {
    const fileTypeHeader: Header = {
      label: 'Type',
      sortable: false,
      dataKey: 'type'
    }
    this.headers.splice(this.headers.length - 1, 0, fileTypeHeader)
  }

  withClientName() {
    const clientNameHeader: Header = {
      label: 'Client',
      sortable: false,
      dataKey: 'clientName'
    }
    this.headers.splice(this.headers.length - 1, 0, clientNameHeader)
  }

  create(): Header[] {
    return this.headers
  }
}

const getReversedDate = (date: string): string => {
  return `${date.split('-')[1]}-${date.split('-')[0]}`
}

const sortByDateAsc = (files: Array<FileItemVM>) => {
  return files.sort((file1: FileItemVM, file2: FileItemVM) => {
    if (!file1.date) return 1
    if (!file2.date) return -1
    const date1 = getReversedDate(file1.date)
    const date2 = getReversedDate(file2.date)
    return date1 > date2 ? 1 : date1 === date2 ? 0 : -1
  })
}

const sortByDateDesc = (files: Array<FileItemVM>) => {
  return files.sort((file1: FileItemVM, file2: FileItemVM) => {
    if (!file1.date) return 1
    if (!file2.date) return -1
    const date1 = getReversedDate(file1.date)
    const date2 = getReversedDate(file2.date)
    return date1 < date2 ? 1 : date1 === date2 ? 0 : -1
  })
}

const getClientName = (clientUuid: string, state: AppState): string => {
  const clients = getAvailableClients(state)
  const client = clients.find((c) => c.uuid === clientUuid)
  if (!client) return ''
  return client.name
}

const createFilesVM = (files: Array<PlFile>, roles: Array<UserRole>, state: AppState): Array<FileItemVM> => {
  const isAdmin = roles.includes(UserRole.Admin)
  return files.map(f => {
    return {
      uuid: f.uuid,
      name: f.name,
      url: f.url,
      type: getFileType(f.name),
      clientName: getClientName(f.clientUuid, state),
      date: getFileDate(f.name),
      canDelete: isAdmin,
      canRename: isAdmin
    }
  })
}

const sortByDate = (files: Array<FileItemVM>, direction: SortType): Array<FileItemVM> => {
  let sortedFiles: Array<FileItemVM> = files
  if (direction === SortType.Ascending) {
    sortedFiles = sortByDateAsc(files)
  } else if (direction === SortType.Descending) {
    sortedFiles = sortByDateDesc(files)
  }
  return sortedFiles
}

const sortByNameAsc = (files: Array<FileItemVM>) => {
  return files.sort((file1: FileItemVM, file2: FileItemVM) => {
    const name1 = file1.name.toLowerCase()
    const name2 = file2.name.toLowerCase()
    return name1 > name2 ? 1 : name1 === name2 ? 0 : -1
  })
}

const sortByNameDesc = (files: Array<FileItemVM>) => {
  return files.sort((file1: FileItemVM, file2: FileItemVM) => {
    const name1 = file1.name.toLowerCase()
    const name2 = file2.name.toLowerCase()
    return name1 > name2 ? -1 : name1 === name2 ? 0 : 1
  })
}


const sortByName = (files: Array<FileItemVM>, direction: SortType): Array<FileItemVM> => {
  let sortedFiles: Array<FileItemVM> = files
  if (direction === SortType.Ascending) {
    sortedFiles = sortByNameAsc(files)
  } else if (direction === SortType.Descending) {
    sortedFiles = sortByNameDesc(files)
  }
  return sortedFiles
}

const sortFiles = (files: Array<FileItemVM>, sort?: Sort) => {
  if (sort?.field === 'date') {
    return sortByDate(files, sort.direction)
  }
  if (sort?.field === 'name') {
    return sortByName(files, sort.direction)
  }
  return files
}

const createHeaders = (roles: Array<UserRole>, withType = false): Header[] => {
  const headersFactory = new AvailableFilesHeadersFactory()
  if (withType) {
    headersFactory.withFileType()
  }
  if (roles.includes(UserRole.Admin)) {
    headersFactory.withClientName()
  }
  return headersFactory.create()
}

export const createAvailableFilesVM = (files: Array<PlFile>, state: AppState, sort?: Sort) => {
  const user = getCurrentUser(state)
  const roles = user?.roles || []
  let filesVM = createFilesVM(files, roles, state)
  const fileTypeFilter = getFileFilters(state).fileType
  if (fileTypeFilter) {
    filesVM = filesVM.filter((f) => f.type === fileTypeFilter)
  }
  const sortedFiles = sortFiles(filesVM, sort)
  const headers = createHeaders(roles, fileTypeFilter === undefined)
  return {
    headers,
    files: sortedFiles
  }
}

export interface Filters {
  fileType: FileType
  search: string
  clients: Array<string>
}

export interface Sort {
  field: string,
  direction: SortType
}

export const getAvailableFilesVM = (sort?: Sort) => (state: AppState): AvailableFilesVM => {
  return createAvailableFilesVM(getAvailableFiles(state), state, sort)
}
