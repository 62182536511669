import React from 'react'

export interface CheckboxProps {
  checked: boolean
  name: string
  onClicked?: any
}

export const Checkbox = (props: CheckboxProps) => {
  const handleClick = () => {
    props.onClicked()
  }
  return (
    <input
      id={props.name}
      name={props.name}
      type="checkbox"
      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      checked={props.checked}
      onChange={handleClick}
    />
  )
}
