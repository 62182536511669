import React from 'react'
import { AvailableAuditListing } from '../available-file-listing/availableAuditsListing.component'

export const Audits = () => {
  return (
    <main>
      <AvailableAuditListing/>
    </main>
  )
}
