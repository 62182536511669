import { ThunkResult } from '../../../store/configureStore'
import { FileGateway } from '../../gateways/fileGateway.interface'
import { getCurrentUser } from '../../../store/reducers/currentUser.reducer'
import { UserRole } from '../client/available-clients-listing/userRole'
import { Actions } from '../available-file-listing/actionCreators'
import { CannotRenameFileError } from './CannotRenameFileError'

export const renameFile: (fileUuid: string, newName: string) => ThunkResult<Promise<void>> =
  (fileUuid: string, newName: string) => async (dispatch, getState, { fileGateway }: { fileGateway: FileGateway }) => {
    const currentUser = getCurrentUser(getState())
    if (!currentUser) {
      throw new CannotRenameFileError(fileUuid)
    }
    if (!currentUser.roles.includes(UserRole.Admin)) {
      throw new CannotRenameFileError(fileUuid)
    }
    await fileGateway.rename(fileUuid, newName)
    const files = await fileGateway.listFiles(currentUser.uuid)
    dispatch(Actions.filesListed(files))
  }

