import React, { useState } from 'react'
import {
  CreateClientVM
} from '../../view-model-generators/clients/create-client-generators/createClientViewModelGenerator'

export enum AvailableActions {
  Create = 'Créer',
  Edit = 'Modifier'
}

interface Props {
  action: AvailableActions
  manageClientVM: CreateClientVM
  onSubmit: any
  onCancel: any
}

export default function ClientForm(props: Props) {
  const [clientVM, setClientVM] = useState(props.manageClientVM)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    props.onSubmit(clientVM)
  }

  const handleEmailChange = (e: any) => {
    e.preventDefault()
    clientVM.email = e.target.value
    setClientVM(Object.assign({name: clientVM.name, email: clientVM.email}, clientVM))
  }

  const handleNameChange = (e: any) => {
    e.preventDefault()
    clientVM.name = e.target.value
    setClientVM(Object.assign({name: clientVM.name, email: clientVM.email}, clientVM))
  }

  const handleCancel = (e: any) => {
    e.preventDefault()
    props.onCancel()
  }

  const title = () => {
    return <h1>{props.action} client</h1>
  }

  return (
    <>
      <div className="container mx-auto">
        {title()}
        <div className="mt-8 w-full bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Nom
              </label>
              <div className="mt-1">
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-tertiary focus:border-tertiary sm:text-sm"
                  value={clientVM.name}
                  onChange={handleNameChange}
                />
              </div>
              <label htmlFor="email" className="mt-6 block text-sm font-medium text-gray-700">
                Adresse e-mail
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-tertiary focus:border-tertiary sm:text-sm"
                  value={clientVM.email}
                  onChange={handleEmailChange}
                />
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-white py-2 px-4 rounded-md shadow-sm text-sm border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  onClick={handleCancel}
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="ml-3 button py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {props.action}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
