import { ThunkResult } from '../../../../store/configureStore'
import { Actions } from '../actionCreators'
import { ClientGateway } from '../../../gateways/clientGateway.interface'
import { getCurrentUser } from '../../../../store/reducers/currentUser.reducer'

export const editClient: (uuid: string, name: string, email: string) => ThunkResult<Promise<void>> =
  (uuid, name, email) => async (dispatch, getState,
    { clientGateway }: { clientGateway: ClientGateway }) => {
    const currentUser = getCurrentUser(getState())
    const client = await clientGateway.edit(uuid, name, email, currentUser.uuid)
    dispatch(Actions.clientEdited(client))
  }
