import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { SearchIcon } from '@heroicons/react/solid'
import { useDispatch, useSelector } from 'react-redux'
import { FileTable } from './adapters/primary/react-components/shared/file-table.component'
import { listAvailableFiles } from './corelogic/usecases/available-file-listing/listAvailableFiles'
import { links } from './links'
import { setSearchFilter } from './corelogic/usecases/file-searching/setSearchFilter'
import { getFileFilters } from './store/reducers/fileList.reducer'
import { resetFileTypeFilter } from './corelogic/usecases/file-searching/resetFileTypeFilter'

function App() {
  const dispatch = useDispatch()

  const { search } = useSelector(getFileFilters)

  useEffect(() => {
    dispatch(listAvailableFiles)
    dispatch(resetFileTypeFilter)
  }, [dispatch])


  const searchBarRenderer = () => {
    return <div className="container mx-auto">
      <div className="relative w-4/5 lg:w-3/5 mx-auto mb-8">
        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          id="search"
          name="search"
          className="block w-full bg-white border border-gray-300 rounded-full py-4 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-tertiary sm:text-sm"
          placeholder="Rechercher un fichier"
          type="search"
          value={search}
          onChange={(e) => dispatch(setSearchFilter(e.target.value))}
        />
      </div>
    </div>
  }

  const filesRenderer = () => {
    if (!search?.length)
      return null
    return <FileTable/>
  }

  const menuRenderer = () => {
    if (search?.length)
      return null
    return <div className="container mx-auto">
      {links.map((link) => (
        <button key={link.name} className="button block h-16 xl:h-24 w-4/5 lg:w-3/5 mb-8 mx-auto rounded-full text-white">
          <Link to={link.url} className="w-full h-full flex justify-center items-center">
            <h1>{link.name}</h1>
          </Link>
        </button>
      ))}
    </div>
  }
  return (
    <>
      {searchBarRenderer()}
      {filesRenderer()}
      {menuRenderer()}
    </>
  )
}

export default App
