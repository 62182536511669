import { ActionsUnion, createAction } from '../../../store/configureStore'
import { PlFile } from './PlFile.interface'
import {
  FileType
} from '../../../adapters/primary/view-model-generators/documents/available-files-generators/availableFilesViewModelGenerator'

export const Actions = {
  filesListed: (files: PlFile[]) => createAction('AVAILABLE_FILES_LISTED', { files }),
  clientFilterUpdated: (clientFilter: string[]) => createAction('CLIENT_FILTER_UPDATED', { clientFilter }),
  searchFilterUpdated: (search: string) => createAction('SEARCH_FILTER_UPDATED', { search }),
  fileTypeFilterUpdated: (fileType: FileType) => createAction('FILE_TYPE_FILTER_UPDATED', { fileType }),
  searchFilterReset: () => createAction('SEARCH_FILTER_RESET'),
  fileTypeFilterReset: () => createAction('FILE_TYPE_FILTER_RESET')
}

export type Actions = ActionsUnion<typeof Actions>
