import { Client } from '../../../../../corelogic/usecases/client/client.interface'
import { CreateClientVM } from '../create-client-generators/createClientViewModelGenerator'

export class EditClientVM extends CreateClientVM {
  constructor(client: Client) {
    super()
    this.client.name = client.name
    this.client.email = client.email
  }
}

export const editClientVM = (client: Client): EditClientVM => {
  return new EditClientVM(client)
}
