import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from '../../../../corelogic/usecases/user-login/loginUser'
import { getCurrentUser } from '../../../../store/reducers/currentUser.reducer'
import { useNavigate } from 'react-router-dom'
import BannerError from '../shared/banner-error.component'

function LoginForm() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)
  const [state, setState] = useState({
    email: '',
    password: ''
  })
  const [error, setError] = useState(false)

  useEffect(() => {
    if (currentUser) {
      navigate('/')
    }
  }, [dispatch, currentUser])

  const handleLogin = async (e: any) => {
    e.preventDefault()
    try {
      await dispatch(loginUser(state.email, state.password))
    } catch (e: any) {
      setError(true)
    }
  }

  const handleEmailChange = (e: any) => {
    const email = e.target.value
    setState({...state, email})
  }

  const handlePasswordChange = (e: any) => {
    const password = e.target.value
    setState({...state, password})
  }

  function renderError() {
    if (!error) {
      return null
    }
    return <BannerError onClose={() => setError(false)} message="Adresse email ou mot de passe incorrect"/>
  }

  return (
    <>
      <button
        onClick={handleLogin}
        className="button block h-16 mt-8 xl:h-24 w-1/5 mx-auto rounded-md text-white"
      >
        Se connecter
      </button>
      {/*<div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">*/}
      {/*  {renderError()}*/}
      {/*  <div className="sm:mx-auto sm:w-full sm:max-w-md">*/}
      {/*    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Connectez vous</h2>*/}
      {/*  </div>*/}

      {/*  <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">*/}
      {/*    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">*/}
      {/*      <form className="space-y-6" onSubmit={handleLogin}>*/}
      {/*        <div>*/}
      {/*          <label htmlFor="email" className="block text-sm font-medium text-gray-700">*/}
      {/*            Adresse e-mail*/}
      {/*          </label>*/}
      {/*          <div className="mt-1">*/}
      {/*            <input*/}
      {/*              id="email"*/}
      {/*              name="email"*/}
      {/*              type="email"*/}
      {/*              autoComplete="email"*/}
      {/*              required*/}
      {/*              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"*/}
      {/*              value={state.email}*/}
      {/*              onChange={handleEmailChange}*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*        </div>*/}

      {/*        <div>*/}
      {/*          <label htmlFor="password" className="block text-sm font-medium text-gray-700">*/}
      {/*            Mot de passe*/}
      {/*          </label>*/}
      {/*          <div className="mt-1">*/}
      {/*            <input*/}
      {/*              id="password"*/}
      {/*              name="password"*/}
      {/*              type="password"*/}
      {/*              autoComplete="current-password"*/}
      {/*              required*/}
      {/*              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"*/}
      {/*              value={state.password}*/}
      {/*              onChange={handlePasswordChange}*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        <div>*/}
      {/*          <button*/}
      {/*            type="submit"*/}
      {/*            className="button w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
      {/*          >*/}
      {/*            Se connecter*/}
      {/*          </button>*/}
      {/*        </div>*/}
      {/*      </form>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  )
}

export default LoginForm
