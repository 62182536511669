import { combineReducers } from 'redux'
import { AppState} from '../AppState.interface'
import * as actionCreators from '../../corelogic/usecases/member/actionCreators'
import { Member } from '../../corelogic/usecases/member/Member.interface'

const data = (state: Member[] = [], action: actionCreators.Actions) => {
  if (action.type === 'AVAILABLE_MEMBERS_LISTED') {
    return action.payload.members
  }
  if (action.type === 'MEMBER_EDITED') {
    const index = state.findIndex((m) => m.uuid === action.payload.member.uuid)
    return [
      ...state.slice(0, index),
      action.payload.member,
      ...state.slice(index + 1, state.length)
    ]
  }
  if (action.type === 'MEMBER_CREATED') {
    return [
      ...state.slice(0, state.length),
      action.payload.member
    ]
  }
  return state
}

export const memberList = combineReducers({
  data
})

export const getAvailableMembers = (state: AppState) => state.coreLogic.memberList.data
