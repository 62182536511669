import React from 'react'
import { AvailableInvoicesListing } from '../available-file-listing/availableInvoicesListing.component'

export const Invoices = () => {
  return (
    <main>
      <AvailableInvoicesListing/>
    </main>
  )
}
