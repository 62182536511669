import { AppState } from '../../../../store/AppState.interface'
import { getUploadedFiles, isUploading } from '../../../../store/reducers/uploadedFileList.reducer'

export interface UploadFilesVM {
  files: UploadFileItemVM[],
  uploading: boolean
}

export interface UploadFileItemVM {
  name: string
}

export const getUploadFilesVM = (state: AppState): UploadFilesVM => {
  const availableUploadedFilesSelector = getUploadedFiles(state)
  return {
    files: availableUploadedFilesSelector.map(f => {
      return {
        name: f.name
      }
    }),
    uploading: isUploading(state)
  }
}
