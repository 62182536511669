import { FileType } from './availableFilesViewModelGenerator'

export const getFileType = (fileName: string): FileType => {
  const lowerCaseName = fileName.toLowerCase()
  for (const fileType in FileType) {
    if (lowerCaseName.startsWith(FileType[fileType as keyof typeof FileType].toLowerCase())) {
      return FileType[fileType as keyof typeof FileType]
    }
  }
  return FileType.HumanResources
}

export const getFileDate = (fileName: string): string => {
  const splitName = fileName.split('-')
  if (!splitName[1] || !splitName[2] || isNaN(+splitName[1]) || isNaN(+splitName[2]))
    return ''
  const year = `20${splitName[1]}`
  const month = splitName[2]
  return `${month}-${year}`
}
