import { ThunkResult } from '../../../store/configureStore'
import { FileGateway } from '../../gateways/fileGateway.interface'
import { Actions } from './actionCreators'
import { getCurrentUser } from '../../../store/reducers/currentUser.reducer'

export const uploadFiles: (clientId: string, ...files: Array<File>) => ThunkResult<Promise<void>> =
  (clientId: string, ...files: Array<File>) => async (dispatch, getState, { fileGateway }: { fileGateway: FileGateway }) => {
    dispatch(Actions.filesUploading())
    // Le timout doit partir dans le filegateway et throw
    const uploadTimeout = setTimeout( () => {
      dispatch(Actions.fileUploadTimeout())
      return
    }, 2000)
    const currentUser = getCurrentUser(getState())
    const uploadedFiles = await fileGateway.upload(clientId, currentUser.uuid, ...files)
    await dispatch(Actions.filesUploaded(uploadedFiles))
  }

