import { ThunkResult } from '../../../store/configureStore'
import { getFileFilters } from '../../../store/reducers/fileList.reducer'
import { Actions } from '../available-file-listing/actionCreators'

export const removeClientFromFilter: (clientUuid: string) => ThunkResult<Promise<void>> =
  (clientUuid: string) => async (dispatch, getState) => {
    const initialFilter = getFileFilters(getState())
    const clientFilter = initialFilter.clients || []
    const filteredArray = clientFilter.filter((uuid: string) => clientUuid !== uuid)
    dispatch(Actions.clientFilterUpdated(filteredArray))
  }

