import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from '../../../../store/reducers/currentUser.reducer'
import { useNavigate } from 'react-router-dom'
import { logoutUser } from '../../../../corelogic/usecases/user-logout/logoutUser'

function Logout() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)

  useEffect(() => {
    dispatch(logoutUser)
    navigate('/login')
  }, [dispatch, currentUser])

  return null
}

export default Logout
