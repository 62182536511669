import { MemberGateway } from '../../corelogic/gateways/memberGateway.interface'
import {
  FileType
} from '../primary/view-model-generators/documents/available-files-generators/availableFilesViewModelGenerator'
import { Member } from '../../corelogic/usecases/member/Member.interface'

export class ApiMemberGateway implements MemberGateway {
  private readonly path: string

  constructor(path: string) {
    this.path = path
  }

  async create(email: string, rights: Array<FileType>, userUuid: string): Promise<Member> {
    const res = await fetch(`${this.path}/members/new/${userUuid}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, rights })
    })
    const created = await res.json()
    return {
      uuid: created.uuid,
      email: created.email,
      availableDocumentTypes: created.rights
    }
  }

  async editRights(memberUuid: string, newRights: Array<FileType>, userUuid: string): Promise<Member> {
    const res = await fetch(`${this.path}/members/edit/${userUuid}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ uuid: memberUuid, rights: newRights })
    })
    const edited = await res.json()
    return {
      uuid: edited.uuid,
      email: edited.email,
      availableDocumentTypes: edited.rights
    }
  }

  async listMembers(userUuid: string): Promise<Member[]> {
    const res = await fetch(`${this.path}/members/${userUuid}`)
    const jsonRes = await res.json()
    const members: Array<Member> = []
    jsonRes.forEach((m: any) => {
      const member: Member = {
        uuid: m.uuid,
        email: m.email,
        availableDocumentTypes: m.rights
      }
      members.push(member)
    })
    return Promise.resolve(members)
  }
}
