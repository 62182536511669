import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAvailableClientsVM
} from '../../view-model-generators/clients/available-clients-generators/availableClientsViewModelGenerator'
import { getCurrentUser } from '../../../../store/reducers/currentUser.reducer'
import { UserRole } from '../../../../corelogic/usecases/client/available-clients-listing/userRole'
import { addClientToFilter } from '../../../../corelogic/usecases/file-searching/addClientToFilter'
import { removeClientFromFilter } from '../../../../corelogic/usecases/file-searching/removeClientFromFilter'
import { getFileFilters } from '../../../../store/reducers/fileList.reducer'
import { Checkbox } from './checkbox.component'

export const FileFilters = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)
  const currentFilters = useSelector(getFileFilters)

  const { clients } = useSelector(getAvailableClientsVM)

  useEffect(() => {
    clients.forEach((c) => {
      dispatch(addClientToFilter(c.uuid))
    })
  }, [])

  const updateClientFilter = (uuid: string) => {
    if (currentFilters?.clients?.includes(uuid)) {
      dispatch(removeClientFromFilter(uuid))
    } else {
      dispatch(addClientToFilter(uuid))
    }
  }

  const clientFilterRenderer = () => {
    if (!currentUser?.roles.includes(UserRole.Admin)) return null
    const availableClients: Array<any> = []
    clients.forEach((c) => {
      availableClients.push(
        <div className="flex items-center" onClick={() => updateClientFilter(c.uuid)}>
          <Checkbox checked={currentFilters?.clients?.includes(c.uuid) || false} name={c.name}/>
          <label
            className="form-check-label inline-block text-gray-800 cursor-pointer pl-1" htmlFor="flexCheckDefault"
          >
            { c.name }
          </label>
        </div>
      )
    })
    return <div className="flex mt-2 space-x-4"> { availableClients } </div>
  }

  return (
    clientFilterRenderer()
  )
}
