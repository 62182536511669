import { combineReducers } from 'redux'
import { AppState} from '../AppState.interface'
import * as actionCreators from '../../corelogic/usecases/available-file-listing/actionCreators'
import { PlFile } from '../../corelogic/usecases/available-file-listing/PlFile.interface'
import {
  Filters
} from '../../adapters/primary/view-model-generators/documents/available-files-generators/availableFilesViewModelGenerator'

const data = (state: PlFile[] = [], action: actionCreators.Actions) => {
  if (action.type === 'AVAILABLE_FILES_LISTED') {
    return Object.assign([], action.payload.files)
  }
  return state
}

const filters = (state: Partial<Filters> = {}, action: actionCreators.Actions ) => {
  if (action.type === 'CLIENT_FILTER_UPDATED') {
    return Object.assign({}, state, { clients: action.payload.clientFilter })
  }
  if (action.type === 'SEARCH_FILTER_UPDATED') {
    return Object.assign({}, state, { search: action.payload.search })
  }
  if (action.type === 'FILE_TYPE_FILTER_UPDATED') {
    return Object.assign({}, state, { fileType: action.payload.fileType })
  }
  if (action.type === 'SEARCH_FILTER_RESET') {
    return Object.assign({}, state, { search: undefined })
  }
  if (action.type === 'FILE_TYPE_FILTER_RESET') {
    return Object.assign({}, state, { fileType: undefined })
  }
  return state
}

export const fileList = combineReducers({
  data,
  filters
})

export const getFileFilters = (state: AppState) => state.coreLogic.fileList.filters

export const getAvailableFiles = (state: AppState) => {
  const allFiles = state.coreLogic.fileList.data
  const filters = state.coreLogic.fileList.filters
  if (!filters) return allFiles
  let res = allFiles
  if (filters.search) {
    res = res.filter((file: PlFile) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return file.name.toLowerCase().includes(filters.search.toLowerCase())
    })
  }
  if (filters.clients) {
    res = res.filter((af) => filters.clients?.includes(af.clientUuid))
  }
  return res
}
