import { ThunkResult } from '../../../../store/configureStore'
import { Actions } from '../actionCreators'
import { getCurrentUser } from '../../../../store/reducers/currentUser.reducer'
import { MemberGateway } from '../../../gateways/memberGateway.interface'

export const listAvailableMembers: ThunkResult<Promise<void>> =
  async (dispatch, getState,
    { memberGateway }: { memberGateway: MemberGateway }) => {
    const currentUser = getCurrentUser(getState())
    const members = await memberGateway.listMembers(currentUser.uuid)
    dispatch(Actions.membersListed(members))
  }
