import React from 'react'

export const Forbidden = () => {
  return (
    <>
      <div id='forbidden-page'>
        <div className="maincontainer">
          <div className="bat">
            <img className="wing leftwing"
              src="https://www.blissfullemon.com/wp-content/uploads/2018/09/bat-wing.png"/>
            <img className="body"
              src="https://www.blissfullemon.com/wp-content/uploads/2018/09/bat-body.png" alt="bat"/>
            <img className="wing rightwing"
              src="https://www.blissfullemon.com/wp-content/uploads/2018/09/bat-wing.png"/>
          </div>
          <div className="bat">
            <img className="wing leftwing"
              src="https://www.blissfullemon.com/wp-content/uploads/2018/09/bat-wing.png"/>
            <img className="body"
              src="https://www.blissfullemon.com/wp-content/uploads/2018/09/bat-body.png" alt="bat"/>
            <img className="wing rightwing"
              src="https://www.blissfullemon.com/wp-content/uploads/2018/09/bat-wing.png"/>
          </div>
          <div className="bat">
            <img className="wing leftwing"
              src="https://www.blissfullemon.com/wp-content/uploads/2018/09/bat-wing.png"/>
            <img className="body"
              src="https://www.blissfullemon.com/wp-content/uploads/2018/09/bat-body.png" alt="bat"/>
            <img className="wing rightwing"
              src="https://www.blissfullemon.com/wp-content/uploads/2018/09/bat-wing.png"/>
          </div>
          <img className="foregroundimg"
            src="https://www.blissfullemon.com/wp-content/uploads/2018/09/HauntedHouseForeground.png"
            alt="haunted house"/>
        </div>
      </div>
      <h1 className="errorcode">ERROR 403</h1>
      <div className="errortext">This area is forbidden. Turn back now!</div>
    </>
  )
}
