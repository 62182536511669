import React from 'react'
import { AvailableProcessListing } from '../available-file-listing/availableProcessListing.component'

export const Process = () => {
  return (
    <main>
      <AvailableProcessListing/>
    </main>
  )
}
