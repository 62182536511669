import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { configureReduxStore } from './store/configureStore'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Invoices } from './adapters/primary/react-components/routes/invoices'
import { Quotes } from './adapters/primary/react-components/routes/quotes'
import { Audits } from './adapters/primary/react-components/routes/audits'
import { Process } from './adapters/primary/react-components/routes/process'
import { OtherFiles } from './adapters/primary/react-components/routes/others'
import { Layout } from './adapters/primary/react-components/layouts/layout.component'
import LoginForm from './adapters/primary/react-components/routes/login'
import PrivateRoute from './adapters/primary/react-components/shared/private-route.component'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import Logout from './adapters/primary/react-components/routes/logout'
import { Clients } from './adapters/primary/react-components/routes/clients'
import AdminRoute from './adapters/primary/react-components/shared/admin-route.component'
import { Forbidden } from './adapters/primary/react-components/routes/forbidden'
import Client from './adapters/primary/react-components/routes/client'
import NewClient from './adapters/primary/react-components/routes/newClient'
import { FakeUuidGenerator } from './adapters/secondary/fakeUuidGenerator'
import EditClient from './adapters/primary/react-components/routes/editClient'
import { ApiFileGateway } from './adapters/secondary/ApiFileGateway'
import { Auth0AuthenticationGateway } from './adapters/secondary/Auth0AuthenticationGateway'
import { InMemoryClientGateway } from './adapters/secondary/inMemoryClientGateway'
import { InMemoryMemberGateway } from './adapters/secondary/InMemoryMemberGateway'
import {
  FileType
} from './adapters/primary/view-model-generators/documents/available-files-generators/availableFilesViewModelGenerator'
import { InMemoryFileGateway } from './adapters/secondary/inMemoryFileGateway'
import {
  AvailableMembersListing
} from './adapters/primary/react-components/available-member-listing/availableMembersListing.component'
import { Members } from './adapters/primary/react-components/routes/members'
import { ApiMemberGateway } from './adapters/secondary/ApiMemberGateway'
import { ApiClientGateway } from './adapters/secondary/ApiClientGateway'

const uuidGenerator: FakeUuidGenerator = new FakeUuidGenerator()
uuidGenerator.setNextUuids('jkl798')

const fileGateway = new ApiFileGateway(process.env.REACT_APP_API_URL || '')
// const fileGateway = new InMemoryFileGateway(uuidGenerator)
// fileGateway.feedWith(
//   {
//     uuid: 'abc123',
//     clientUuid: 'abc123',
//     name: 'FACTURE-22-03-20-test1.pdf',
//     url: '/tests/FACTURE-22-03-20-test1.pdf'
//   },
//   {
//     uuid: 'def456',
//     clientUuid: 'def456',
//     name: 'FACTURE-23-04-2-test2.pdf',
//     url: '/tests/FACTURE-23-04-2-test2.pdf'
//   },
//   {
//     uuid: 'xyz123',
//     clientUuid: 'abc123',
//     name: 'FACTURE-23-04-3-test3.pdf',
//     url: '/tests/FACTURE-23-04-3-test3.pdf'
//   },
//   {
//     uuid: 'xyz456',
//     clientUuid: 'def456',
//     name: 'FACTURE-23-04-4-test4.pdf',
//     url: '/tests/FACTURE-23-04-4-test4.pdf'
//   },
//   {
//     uuid: 'ghi789',
//     clientUuid: 'abc123',
//     name: 'DEVIS-22-12-12-test1.pdf',
//     url: '/tests/DEVIS-22-12-12-test1.pdf'
//   },
//   {
//     uuid: 'jkl789',
//     clientUuid: 'abc123',
//     name: 'DEVIS-22-03-20-test2.pdf',
//     url: '/tests/DEVIS-22-03-20-test2.pdf'
//   },
//   {
//     uuid: 'mno123',
//     clientUuid: 'abc123',
//     name: 'AUDIT-22-04-20-test1.pdf',
//     url: '/tests/AUDIT-22-04-20-test1.pdf'
//   },
//   {
//     uuid: 'pqr456',
//     clientUuid: 'abc123',
//     name: 'AUDIT-22-03-20-test2.pdf',
//     url: '/tests/AUDIT-22-03-20-test2.pdf'
//   },
//   {
//     uuid: 'stu789',
//     clientUuid: 'abc123',
//     name: 'PROCESS-22-03-20-test1.pdf',
//     url: '/tests/PROCESS-22-03-20-test1.pdf'
//   },
//   {
//     uuid: 'vwx123',
//     clientUuid: 'abc123',
//     name: 'PROCESS-22-07-20-test2.pdf',
//     url: '/tests/PROCESS-22-07-20-test2.pdf'
//   },
//   {
//     uuid: 'yza465',
//     clientUuid: 'abc123',
//     name: 'fiche recrutement vierge.pdf',
//     url: '/tests/fiche recrutement vierge.pdf'
//   },
//   {
//     uuid: 'bcd798',
//     clientUuid: 'abc123',
//     name: 'diagramme personnel.pdf',
//     url: '/tests/diagramme personnel.pdf'
//   }
// )

export const authenticationGateway = new Auth0AuthenticationGateway()
// export const authenticationGateway = new InMemoryAuthenticationGateway()
// authenticationGateway.feedWith(
//   { uuid: 'toto-uuid', email: 'toto@toto.com', role: UserRole.Client },
//   { uuid: 'abc123', email: 'cedric.marmouget@pharlog.fr', role: UserRole.Admin },
//   { uuid: 'ghi789', email: 'demo@pharlog.fr', role: UserRole.Client },
//   { uuid: 'def456', email: 'admin@toto.com', role: UserRole.Admin }
// )

const clientGateway = new ApiClientGateway(process.env.REACT_APP_API_URL || '')

// const clientGateway = new InMemoryClientGateway(uuidGenerator)
// clientGateway.feedWith(
//   {
//     uuid: 'abc123',
//     name: 'Client1',
//     email: 'client1@email.fr',
//     invited: true
//   },
//   {
//     uuid: 'def456',
//     name: 'Client2',
//     email: 'client2@email.fr',
//     invited: false
//   }
// )

const memberGateway = new ApiMemberGateway(process.env.REACT_APP_API_URL || '')
// const memberGateway = new InMemoryMemberGateway(uuidGenerator)
// memberGateway.feedWith(
//   {
//     uuid: 'abc123',
//     email: 'toto@toto.com',
//     availableDocumentTypes: [FileType.InVoice, FileType.Quote]
//   },
//   {
//     uuid: 'def456',
//     email: 'tata@tata.com',
//     availableDocumentTypes: [FileType.Process]
//   },
//   {
//     uuid: 'ghi789',
//     email: 'titi@titi.com',
//     availableDocumentTypes: [FileType.HumanResources]
//   }
// )

const store = configureReduxStore({ fileGateway, authenticationGateway, clientGateway, memberGateway })

const persistor = persistStore(store)

const init = async () => {
  await authenticationGateway.init(process.env.REACT_APP_OAUTH_DOMAIN || '', process.env.REACT_APP_OAUTH_CLIENT_ID || '', process.env.REACT_APP_OAUTH_REDIRECT_URI || '', process.env.REACT_APP_OAUTH_ROLE_URL || '')
}

init().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout><PrivateRoute><App /></PrivateRoute></Layout>} />
              <Route path="/login" element={<LoginForm />} />
              <Route path="/logout" element={<Layout><Logout /></Layout>} />
              <Route path="/factures" element={<Layout><PrivateRoute>{<Invoices />}</PrivateRoute></Layout>} />
              <Route path="/devis" element={<Layout><PrivateRoute>{<Quotes />}</PrivateRoute></Layout>} />
              <Route path="/audits" element={<Layout><PrivateRoute>{<Audits />}</PrivateRoute></Layout>} />
              <Route path="/process" element={<Layout><PrivateRoute>{<Process />}</PrivateRoute></Layout>} />
              <Route path="/ressources-humaines" element={<Layout><PrivateRoute>{<OtherFiles />}</PrivateRoute></Layout>} />
              <Route path="/clients/" element={<Layout><AdminRoute>{<Clients />}</AdminRoute></Layout>} />
              <Route path="/clients/new" element={<Layout><AdminRoute>{<NewClient />}</AdminRoute></Layout>} />
              <Route path="/clients/:clientId" element={<Layout><AdminRoute>{<Client />}</AdminRoute></Layout>} />
              <Route path="/clients/:clientId/edit" element={<Layout><AdminRoute>{<EditClient />}</AdminRoute></Layout>} />
              <Route path="/membres" element={<Layout><PrivateRoute>{<Members />}</PrivateRoute></Layout>} />
              <Route path="/403" element={<Forbidden />} />
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals({})
