import { ClientGateway } from '../../corelogic/gateways/clientGateway.interface'
import { Client } from '../../corelogic/usecases/client/client.interface'

export class ApiClientGateway implements ClientGateway {
  private readonly path: string

  constructor(path: string) {
    this.path = path
  }

  async invite(uuid: string): Promise<Client> {
    const res = await fetch(`${this.path}/clients/invite/${uuid}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    const invited = await res.json()
    return {
      uuid: invited.uuid,
      name: invited.name,
      email: invited.email,
      invited: invited.invited
    }
  }

  async create(name: string, email: string, userUuid: string): Promise<Client> {
    const res = await fetch(`${this.path}/clients/new/${userUuid}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, email })
    })
    const created = await res.json()
    return {
      uuid: created.uuid,
      name: created.name,
      email: created.email,
      invited: created.invited
    }
  }

  async edit(uuid: string, name: string, email: string, userUuid: string): Promise<Client> {
    const res = await fetch(`${this.path}/clients/edit/${userUuid}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ uuid, name, email })
    })
    const edited = await res.json()
    return {
      uuid: edited.uuid,
      name: edited.name,
      email: edited.email,
      invited: edited.invited
    }
  }

  async listClients(userUuid: string): Promise<Client[]> {
    const res = await fetch(`${this.path}/clients/${userUuid}`)
    const jsonRes = await res.json()
    const clients: Array<Client> = []
    jsonRes.forEach((c: any) => {
      const client: Client = {
        uuid: c.uuid,
        name: c.name,
        email: c.email,
        invited: c.invited
      }
      clients.push(client)
    })
    return clients
  }
}
