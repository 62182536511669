import { ThunkResult } from '../../../store/configureStore'
import { getFileFilters } from '../../../store/reducers/fileList.reducer'
import { Actions } from '../available-file-listing/actionCreators'

export const addClientToFilter: (clientUuid: string) => ThunkResult<Promise<void>> =
  (clientUuid: string) => async (dispatch, getState) => {
    const initialFilter = getFileFilters(getState())
    const clientFilter = initialFilter.clients || []
    clientFilter.push(clientUuid)
    dispatch(Actions.clientFilterUpdated(clientFilter))
  }

