import { ActionsUnion, createAction } from '../../../store/configureStore'
import { Member } from './Member.interface'

export const Actions = {
  membersListed: (members: Member[]) => createAction('AVAILABLE_MEMBERS_LISTED', { members }),
  memberEdited: (member: Member) => createAction('MEMBER_EDITED', { member }),
  memberCreated: (member: Member) => createAction('MEMBER_CREATED', { member })
}

export type Actions = ActionsUnion<typeof Actions>
