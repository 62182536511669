import { ActionsUnion, createAction } from '../../../store/configureStore'
import { PlFile } from '../available-file-listing/PlFile.interface'

export const Actions = {
  filesUploaded: (files: PlFile[]) => createAction('FILES_UPLOADED', { files }),
  filesUploading: () => createAction('FILES_UPLOADING'),
  fileUploadTimeout: () => createAction('FILE_UPLOAD_TIMEOUT')
}

export type Actions = ActionsUnion<typeof Actions>
