import { Member } from '../../../../../corelogic/usecases/member/Member.interface'
import { FileType } from '../../documents/available-files-generators/availableFilesViewModelGenerator'

export class CreateMemberVM {
  protected member: Partial<Member> = {}

  constructor() {
    this.member.email = ''
    this.member.availableDocumentTypes = []
  }

  copy(): CreateMemberVM {
    const res = new CreateMemberVM()
    res.email = this.email
    res.rights = this.rights
    return res
  }

  get canChangeEmail(): boolean {
    return true
  }

  get email(): string {
    return this.member.email || ''
  }

  set email(email: string) {
    this.member.email = email
  }

  get rights(): Array<FileType> {
    return this.member.availableDocumentTypes || []
  }

  set rights(newRights: Array<FileType>) {
    this.member.availableDocumentTypes = newRights
  }
}

export const createMemberVM = () => {
  return new CreateMemberVM()
}


