import React from 'react'
import NavBar from '../navigation/NavBar.component'
import Footer from './footer.component'

export const Layout = ({children}: any) => {
  return (
    <>
      <div className="flex flex-col h-screen">
        <div className="flex-none">
          <NavBar />
        </div>
        <main className="mt-10 grow">
          {children}
        </main>
        <div className="flex-none">
          <Footer/>
        </div>
      </div>
    </>
  )
}
