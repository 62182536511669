import { combineReducers } from 'redux'
import { AppState} from '../AppState.interface'
import * as actionCreators from '../../corelogic/usecases/user-login/actionCreators'
import { User } from '../../corelogic/usecases/user-login/User.interface'

const data = (state: User | null = null, action: actionCreators.Actions) => {
  if (action.type === 'USER_LOGGED_IN') {
    return action.payload.user
  }
  if (action.type === 'USER_LOGGED_OUT') {
    return null
  }
  return state
}

export const currentUser = combineReducers({
  data
})

export const getCurrentUser = (state: AppState) => state.coreLogic.currentUser.data
