import React from 'react'
import { AvailableQuotesListing } from '../available-file-listing/availableQuotesListing.component'

export const Quotes = () => {
  return (
    <main>
      <AvailableQuotesListing/>
    </main>
  )
}
