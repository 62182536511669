import { ThunkResult } from '../../../store/configureStore'
import { FileGateway } from '../../gateways/fileGateway.interface'
import { getCurrentUser } from '../../../store/reducers/currentUser.reducer'
import { UserRole } from '../client/available-clients-listing/userRole'
import { CannotDeleteFileError } from './CannotDeleteFileError'
import { Actions } from '../available-file-listing/actionCreators'

export const deleteFile: (fileUuid: string) => ThunkResult<Promise<void>> =
  (fileUuid: string) => async (dispatch, getState, { fileGateway }: { fileGateway: FileGateway }) => {
    const currentUser = getCurrentUser(getState())
    if (!currentUser) {
      throw new CannotDeleteFileError()
    }
    if (!currentUser.roles.includes(UserRole.Admin)) {
      throw new CannotDeleteFileError()
    }
    await fileGateway.delete(fileUuid)
    const files = await fileGateway.listFiles(currentUser.uuid)
    dispatch(Actions.filesListed(files))
  }

