import { AppState } from '../../../../../store/AppState.interface'
import { getAvailableMembers } from '../../../../../store/reducers/memberList.reducer'
import { FileType } from '../../documents/available-files-generators/availableFilesViewModelGenerator'
import { getEnumKeys } from '../../../../../corelogic/utils/enumsUtils'

export interface AvailableMembersVM {
  members: Array<MemberVM>
}

export interface MemberVM {
  uuid: string
  email: string
  rights: Map<FileType, boolean>
}

const computeRights = (availableDocumentsTypes: Array<FileType>): Map<FileType, boolean> => {
  const res = new Map<FileType, boolean>()
  const allDocumentsTypes = getEnumKeys(FileType)
  allDocumentsTypes.forEach((key) => {
    if (availableDocumentsTypes.includes(key)) {
      res.set(key, true)
    } else {
      res.set(key, false)
    }
  })
  return res
}

export const getAvailableMembersVM = (state: AppState): AvailableMembersVM => {
  const availableMembersSelector = getAvailableMembers(state)
  return {
    members: availableMembersSelector.map(m => {
      return {
        uuid: m.uuid,
        email: m.email,
        rights: computeRights(m.availableDocumentTypes)
      }
    })
  }
}
