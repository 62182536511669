import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { listAvailableFiles } from '../../../../corelogic/usecases/available-file-listing/listAvailableFiles'
import { FileTable } from '../shared/file-table.component'
import {
  FileType
} from '../../view-model-generators/documents/available-files-generators/availableFilesViewModelGenerator'
import { setFileTypeFilter } from '../../../../corelogic/usecases/file-searching/setFileTypeFilter'
import { resetSearchFilter } from '../../../../corelogic/usecases/file-searching/resetSearchFilter'

export const AvailableProcessListing = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listAvailableFiles)
    dispatch(setFileTypeFilter(FileType.Process))
    dispatch(resetSearchFilter)
  }, [dispatch])

  return (
    <div className="container mx-auto">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h2>Process</h2>
        </div>
      </div>
      <FileTable/>
    </div>
  )}
