import { ThunkResult } from '../../../store/configureStore'
import { Actions } from './actionCreators'
import { AuthenticationGateway } from '../../gateways/authenticationGateway.interface'

export const loginUser: (email: string, password: string) => ThunkResult<Promise<void>> =
  (email: string, password: string) => async (dispatch, _getState, { authenticationGateway }: { authenticationGateway: AuthenticationGateway }) => {
    const user = await authenticationGateway.authenticate(email, password)
    dispatch(Actions.userLoggedIn(user))
  }

