import React, { useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon, DownloadIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/solid'
import {
  FileItemVM, getAvailableFilesVM, SortType
} from '../../view-model-generators/documents/available-files-generators/availableFilesViewModelGenerator'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFile } from '../../../../corelogic/usecases/file-deleting/deleteFile'
import AlertSuccess from './alert-success.component'
import Modal from './modal'
import { renameFile } from '../../../../corelogic/usecases/file-renaming/renameFile'
import { FileFilters } from './file-filters.component'

interface Header {
  label: string
  sortable: boolean
  dataKey: string
}

export const FileTable = () => {
  const dispatch = useDispatch()
  const [sortType, setSortType] = useState(SortType.None)
  const [sortColumn, setSortColumn] = useState('')
  const { files, headers } = useSelector(getAvailableFilesVM({ field: sortColumn, direction: sortType }))
  const [displayAlert, setDisplayAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const [open, setOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState({})

  const sortChanged = (column: string) => {
    setSortColumn(column)
    setSortType((sortType + 1) % 3)
  }

  const headersRenderer = (headers: Array<Header>): Array<any> => {
    const res: Array<any> = []
    headers.forEach((header: Header) => {
      let th
      if (header.sortable) {
        let sortChevron: any
        if (sortType === SortType.Ascending && header.dataKey === sortColumn) {
          sortChevron = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        } else if (sortType === SortType.Descending && header.dataKey === sortColumn) {
          sortChevron = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
        } else {
          sortChevron = <ChevronDownIcon className="invisible group-hover:visible group-focus:visible h-5 w-5" aria-hidden="true" />
        }
        th = <th key={header.dataKey} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
          <div onClick={() => sortChanged(header.dataKey) } className="group inline-flex hover:cursor-pointer">
            {header.label}
            <span className="ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
              {sortChevron}
            </span>
          </div>
        </th>
      } else {
        th = <th key={header.dataKey} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
          <div className="group inline-flex">
            {header.label}
          </div>
        </th>
      }
      res.push(th)
    })
    return res
  }

  const deleteFileAction = (file: FileItemVM) => async (e: any) => {
    e.preventDefault()
    await dispatch(deleteFile(file.uuid))
    setAlert(`Le document ${file.name} a bien été supprimé`)
  }

  const renameFileAction = (file: FileItemVM) => async (e: any) => {
    e.preventDefault()
    setOpen(true)
    setSelectedFile(file)
  }

  const setAlert = (message: string) => {
    setDisplayAlert(true)
    setAlertMessage(message)
  }

  const alert = () => {
    if (displayAlert) {
      return <div className="mb-8">
        <AlertSuccess
          onClosed={() => {
            setDisplayAlert(false)
            setAlertMessage('')
          }}
          text={alertMessage}
        />
      </div>
    }
  }

  const rowRenderer = (headers: Array<Header>, file: FileItemVM): Array<any> => {
    const res: Array<any> = []
    headers.forEach((header: Header, index) => {
      let content
      if (header.dataKey === 'name') {
        content = <a href={file.url} rel="noreferrer" target="_blank">{file.name}</a>
      } else if (header.dataKey === 'date') {
        content = file.date
      } else if (header.dataKey === 'clientName') {
        content = file.clientName
      } else if (header.dataKey === 'actions') {
        // eslint-disable-next-line react/jsx-key
        const availableActions = [<a href={file.url} rel="noreferrer" target="_blank"> <DownloadIcon className="block h-6 w-6" aria-hidden="true"/> </a>]
        if (file.canRename) availableActions.push(<a className="hover:cursor-pointer ml-4" onClick={renameFileAction(file)}> <PencilAltIcon className="block h-6 w-6" aria-hidden="true"/> </a>)
        if (file.canDelete) availableActions.push(<a className="hover:cursor-pointer ml-4" onClick={deleteFileAction(file)}> <TrashIcon className="block h-6 w-6" aria-hidden="true"/> </a>)
        content = <div className="flex"> {availableActions} </div>
      } else {
        content = file.type
      }
      const td = <td key={index} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{content}</td>
      res.push(td)
    })
    return res
  }

  const closeModal = () => {
    setOpen(false)
  }

  const handleNameChange = (e: any) => {
    e.preventDefault()
    const newName = e.target.value
    selectedFile.name = newName
    const newFile = Object.assign({}, selectedFile)
    setSelectedFile(newFile)
  }

  const handleRenameFile = async () => {
    await dispatch(renameFile(selectedFile.uuid, selectedFile.name))
    closeModal()
    setAlert('Le fichier a bien été renommé')
  }

  const modalRenderer = () => {
    return <Modal open={open} title="Renommer fichier" action="Renommer" close={closeModal} validate={handleRenameFile} >
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700 text-left">
          Nom du fichier
        </label>
        <div className="mt-1">
          <input
            id="name"
            name="name"
            type="text"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-tertiary focus:border-tertiary sm:text-sm text-gray-700"
            value={selectedFile.name}
            onChange={handleNameChange}
          />
        </div>
      </div>
    </Modal>
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8 text-left">
      {alert()}
      {modalRenderer()}
      <FileFilters></FileFilters>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {headersRenderer(headers)}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {files.map((file: FileItemVM, index: number) => (
                    <tr key={index}>
                      {rowRenderer(headers, file)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
