import React from 'react'
import { AvailableOtherFilesListing } from '../available-file-listing/availableOtherFilesListing.component'

export const OtherFiles = () => {
  return (
    <main>
      <AvailableOtherFilesListing/>
    </main>
  )
}
