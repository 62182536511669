import { currentUser } from './currentUser.reducer'
import { fileList } from './fileList.reducer'
import { combineReducers } from 'redux'
import { clientList } from './clientList.reducer'
import { uploadedFileList } from './uploadedFileList.reducer'
import { memberList } from './memberList.reducer'

export const coreLogic = combineReducers({
  currentUser,
  fileList,
  clientList,
  uploadedFileList,
  memberList,
})
